<template>
  <div class="vaccine">
    <project-page
      title="COVID-19 Vaccine Notification Service"
      locationDate="Québec, April 2021"
      filename="vaxx.jpg"
      notionId="Vaccine-Notification-Service-1e0148f1124f4bd2a47ff16b2965b017"
    />
  </div>
</template>

<script>
import ProjectPage from './ProjectPage.vue';

export default {
  components: { ProjectPage },
  name: "Vaccine",
};
</script>

<style scoped>

</style>
