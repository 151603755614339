<template>
  <div class="image-compression">
    <project-page
      title="Introduction to Image Compression"
      locationDate="Québec, March 2021"
      filename="image.jpg"
      notionId="Image-Compression-b228751e26c8481188605129720ae9c3"
      github
      githubLink="https://github.com/simkass/image-compression"
    />
  </div>
</template>

<script>
import ProjectPage from './ProjectPage.vue';

export default {
  components: { ProjectPage },
  name: "ImageCompression",
};
</script>

<style scoped>

</style>
